import { Auth } from 'aws-amplify';
import { AImage } from 'components/atoms';
import { useState } from 'react';
import { logoutIcon } from 'utils/assets';
import { logout as logoutCleanState } from 'store';
import './ProfileCircleCard.scss'
import { useAppDispatch } from 'store/hooks';

export const ProfileCircleCard = ({ name, secondName, header = false }: any) => {
    const [logout, setLogout] = useState(false);
    const dispatch = useAppDispatch();
    const handleClick = async () => {
        await Auth.signOut();
        dispatch(logoutCleanState());
    };
    const LOGOUT = 'Cerrar sesión';

    return (
        <div className='profile-card' onClick={() => setLogout(prevLogout => !prevLogout)}>
            <div className={`profile-card__circle ${header ? "profile-card__header" : ""}`}
                data-testid="profile">
                {name?.toUpperCase()}{secondName?.toUpperCase()}
            </div>
            {logout && <div className='logout-button fadeInDown' onClick={() => handleClick()}>
                <AImage
                    className="header_logo__image"
                    url={logoutIcon}
                    alt=""
                    dataTestid="desktop-logo"
                />
                <p>{LOGOUT}</p>
            </div>}
        </div>
    );
}