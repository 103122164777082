export const arrayFirstElement = 0;

export const nameNormalizingMethod = 'NFD';

export const HOME_HIRING = '/home-hiring';

export const HOME_HIRING_LENGTH = 12;

export const pdfExtension = 'pdf';

export const SPECIFICATIONS_APROX = '<strong>Especificaciones aproximadas:</strong>';

export const ImageUploadConstants = {
  ACCEPTED_TYPES: {
    JPEG: 'jpeg',
    PNG: 'png',
    JPG: 'jpg',
  },
  SUPPORT_MESSAGE: {
    SUCCESS:
      'El equipo de soporte se contactará contigo para reprogramar tu entrevista.',
    DEFAULT:
      'Estudiaremos tu caso. Revisa tu correo durante el día para conocer la respuesta por parte del equipo de soporte.',
  },
  SUCCESS_SUPPORT_ID: 2,
  FILE_LIMIT_EXCEEDED_MESSAGE:
    'Recuerda que el peso máximo es de 8 MB por ambas fotos',
  FILE_LIMIT_EXCEEDED_TITLE: '¡Ups! El archivo supera las 8 MB',
  INVALID_FILE_TYPE_MESSAGE: 'El archivo debe ser de tipo JPG o PNG.',
  MAX_FILE_SIZE_MB: 8,
  HTTP_STATUS_CODES: {
    CONTINUE: 100,
    OK: 200,
    MULTIPLE_CHOICES: 300,
    BAD_REQUEST: 400,
    INTERNAL_SERVER_ERROR: 500,
  },
};

export const barsLoader = {
  initialPercent: 0,
  intervalDuration: 200,
  percentToAdd: 10,
  maxPercent: 100,
};

export const fileUploader = {
  percentDelay: 2000,
  minimumDelay: 0,
};

export const scheduleVariables = {
  year: 'numeric',
  month: 'long',
  locale: 'es-ES',
};

export const dayParts = {
  am: 'am',
  pm: 'pm',
};

export const dayAndNight = {
  day: 'day',
  night: 'night',
};

export const hourText = 'hour';

export const userEmailMarker = '€1';

export const itemsStatus = {
  COMPLETED: '4',
  APPROVED: '6',
};
