import { IActivitiesByIdStage, IConfirmSchedule, IEmail, IGetActivity, IGetWaitMessageActivity, INoCurrentActivity, InterviewInfo, IResponse, IResponseActivitiesByIdStage, IResponseMeet, IResponseSchedule, IStatus } from "utils/interfaces";
import { talentoApi } from "./index";

const stagesExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivitiesByIdStage: builder.query<
      IResponseActivitiesByIdStage,
      IActivitiesByIdStage
    >({
      query({ idStage, idVacant, email }) {
        return {
          url: `/stages/content?idActivity=${idStage}&idVacant=${idVacant}&email=${email}`,
        };
      },
    }),

    getActivityStatus: builder.query({
      query({ idStage, idVacant, email }) {
        return {
          url: `/stages/status?idActivity=${idStage}&idVacant=${idVacant}&email=${email}`,
        };
      },
    }),

    changeStatus: builder.mutation<IResponse, IStatus>({
      query: ({ idStage, idVacant, email, status, activityName }) => ({
        url: `/stages/change-status?idActivity=${idStage}&idVacant=${idVacant}&email=${email}&status=${status}&activityName=${activityName}`,
        method: 'PUT',
      }),
    }),

    getListCalendar: builder.query({
      query({ idActivity, email }) {
        return {
          url: `/stages/list-calendar?idActivity=${idActivity}&email=${email}`,
        };
      },
    }),

    scheduleMeeting: builder.mutation({
      query: ({ idEvent, idCalendar, email, idActivity, candidateFullName }) => ({
        url: `/stages/schedule-meeting`,
        method: 'POST',
        body: {
          idEvent, idCalendar, invitedEmail: email, idActivity, candidateFullName
        }
      }),
    }),

    confirmSchedule: builder.mutation<IResponseMeet, IConfirmSchedule>({
      query: ({ idActivity, email, startUnixDate }) => ({
        url: `/stages/schedules/${startUnixDate}?email=${email}&idActivity=${idActivity}`,
        method: 'POST',
      }),
    }),

    getSchedule: builder.query<IResponseSchedule, IEmail>({
      query({ email, idActivity }) {
        return {
          url: `/stages/schedules?email=${email}&idActivity=${idActivity}`,
        };
      },
    }),

    getEnterviewData: builder.query<InterviewInfo, IEmail>({
      query({ idActivity, email }) {
        return {
          url: `/stages/meets/${email}?idActivity=${idActivity}`,
        };
      },
    }),

    getNoCurrentActivity: builder.query<INoCurrentActivity, IGetActivity>({
      query: ({ idStage, idVacant, email }) =>
        `stages/waiting-message?idStage=${idStage}&idVacant=${idVacant}&email=${email}`,
      transformResponse: (response: { data: INoCurrentActivity }) =>
        response.data,
    }),

    getWaitingMessageForActivity: builder.query<
      INoCurrentActivity,
      IGetWaitMessageActivity
    >({
      query: ({ idActivity, idVacant, email }) => ({
        url: `/stages/waiting-message-activity?idActivity=${idActivity}&idVacant=${idVacant}&email=${email}`,
      }),
    }),
  }),
});

export const {
  useGetActivitiesByIdStageQuery,
  useGetActivityStatusQuery,
  useChangeStatusMutation,
  useGetListCalendarQuery,
  useScheduleMeetingMutation,
  useConfirmScheduleMutation,
  useGetScheduleQuery,
  useGetEnterviewDataQuery,
  useGetNoCurrentActivityQuery,
  useGetWaitingMessageForActivityQuery,

} = stagesExtensionApi;